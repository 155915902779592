import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'rs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rs-ui';
  // data = [
  //   {
  //     name: 'Burner',
  //     series: [
  //       { 'value': 78.23, 'name': '2018-10-23T08:57:55.532Z', },
  //       { 'value': 74.71, 'name': '2018-10-23T09:57:56.660Z', },
  //       { 'value': 72.07, 'name': '2018-10-23T10:57:55.989Z', },
  //       { 'value': 71.19, 'name': '2018-10-23T11:57:57.710Z', },
  //       { 'value': 70.32, 'name': '2018-10-23T12:57:55.839Z', },
  //       { 'value': 69.44, 'name': '2018-10-23T13:57:57.567Z', },
  //       { 'value': 68.56, 'name': '2018-10-23T14:57:59.897Z', }
  //     ]
  //   },

  //   // {
  //   //   name: 'USA',
  //   //   series: [
  //   //     {
  //   //       name: '2010',
  //   //       value: 7870000
  //   //     },
  //   //     {
  //   //       name: '2011',
  //   //       value: 8270000
  //   //     }
  //   //   ]
  //   // }
  // ];

  // single: any[];
  // multi: any[];

  // view: any[] = [700, 400];

  // // options
  // showXAxis = true;
  // showYAxis = true;
  // gradient = false;
  // showLegend = true;
  // showXAxisLabel = true;
  // xAxisLabel = 'Country';
  // showYAxisLabel = true;
  // yAxisLabel = 'Population';

  // colorScheme = {
  //   domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  // };

  // // line, area
  // autoScale = true;

  // constructor(private httpClient: HttpClient) {
  //   // Object.assign(this, { single, multi })
  //   this.httpClient.get('http://kyngster.com:8000/history/6/1').subscribe(data => {

  //   });
  // }

  // onSelect(event) {
  //   console.log(event);
  // }
}
