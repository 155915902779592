import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { RsPayloadService, IRsPayload } from '../services/rsPayload.service';

@Component({
  selector: 'rs-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  data = [
    {
      name: 'Burner',
      series: [
        // { 'value': 78.23, 'name': '2018-10-23T08:57:55.532Z', },
        // { 'value': 74.71, 'name': '2018-10-23T09:57:56.660Z', },
        // { 'value': 72.07, 'name': '2018-10-23T10:57:55.989Z', },
        // { 'value': 71.19, 'name': '2018-10-23T11:57:57.710Z', },
        // { 'value': 70.32, 'name': '2018-10-23T12:57:55.839Z', },
        // { 'value': 69.44, 'name': '2018-10-23T13:57:57.567Z', },
        // { 'value': 68.56, 'name': '2018-10-23T14:57:59.897Z', }
      ]
    }

    // {
    //   name: 'USA',
    //   series: [
    //     {
    //       name: '2010',
    //       value: 7870000
    //     },
    //     {
    //       name: '2011',
    //       value: 8270000
    //     }
    //   ]
    // }
  ];

  single: any[];
  multi: any[];

  view: any[] = [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Date';
  showYAxisLabel = true;
  yAxisLabel = 'Temperature';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  saveChanges(pl: IRsPayload) {
    pl.edit = false;
    this.rsPayloadService.saveChanges(pl);
  }

  // line, area
  autoScale = true;

  constructor(
    private httpClient: HttpClient,
    public rsPayloadService: RsPayloadService
  ) {
    // Object.assign(this, { single, multi })
  }

  onSelect(event) {
    console.log(event);
  }

  async ngOnInit() {
    // Promise.all([this.httpClient.get('http://kyngster.com:8000/api/history/6/1').toPromise().then((data: any) => {
    //   console.log('6-1', data);
    //   return {
    //     name: 'Burner', series: data.minutely
    //       .map(d => ({ name: moment(d.createdAtDate).format('MM/DD/YYYY h:mm a'), value: d.value }))
    //   };
    // }), this.httpClient.get('http://kyngster.com:8000/api/history/6/2').toPromise().then((data: any) => {
    //   console.log('6-2', data);
    //   return {
    //     name: 'Outside', series: data.minutely
    //       .map(d => ({ name: moment(d.createdAtDate).format('MM/DD/YYYY h:mm a'), value: d.value }))
    //   };
    // })]).then(data => {
    //   this.data = data;
    // });
  }
}
