import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

export interface IRsPayload {
  devId: number; // 1
  bindingId: number; // 1
  // unsigned long id,           // 4
  // uint8_t from,     // 1
  // uint8_t to,       // 1
  action: number; // 1
  // uint8_t origin,   // 1 //original action
  value: number; // 4
  valueB: number; // 4 4,294,967,295
  pin: number; // 1
  pinState: number; // 1
  boundPin: number; // 1
  boundState: number; // 1
  defaultState: number; // 1
  highLow: number; // 1
  target: number; // 4
  threshold: number; // 4
  killThreshold: number; // 4
  manual: number; // 1
  devType: number; // 1
  displayValue?: string;
  displayTarget?: string;
  loading: boolean;
  createdAtDate: Date;
  direction: string;
  name: string;
  edit: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RsPayloadService {
  devs: IRsPayload[] = [];

  namesByDevId: {
    [index: number]: string | undefined;
    [index: string]: string | undefined;
  } = {
    '1-0': 'Pulse cnt',
    '3-0': 'Fermentation Room',
    '3-1': 'Fermentation Room',
    '3-2': 'Fermentation Room',
    '5-0': 'West Flood',
    '6-2': 'Outside',
    '6-1': 'Wood Burner',
    '6-0': 'Wood Flood'
  };

  public isLoading = true;

  constructor(private socket: Socket) {
    console.log('connecting');
    socket.on('connect', () => {
      console.log('connected');
      this.isLoading = false;
    });

    // There is a problem here because this subscription is never turned off
    // So it would appear the user is never changed even after log out
    this.socket.on('payload', (payload: IRsPayload) => {
      // tslint:disable-next-line:no-console

      const found = this.devs.filter(
        pl => pl.devId === payload.devId && pl.bindingId === payload.bindingId
      )[0];
      if (found) {
        if (found.edit) {
          return;
        }
        console.log('received payload update', payload);
        found.boundState = payload.boundState;
        found.manual = payload.manual;
        found.killThreshold = payload.killThreshold;
        found.threshold = payload.threshold;
        found.target = payload.target;
        found.value = payload.value;
        found.valueB = payload.valueB;
        found.displayValue = payload.displayValue;
        found.loading = false;
        found.createdAtDate = payload.createdAtDate;
        found.direction = payload.direction;
      } else {
        console.log('received new payload', payload);
        payload.direction = 'N';
        payload.name =
          this.namesByDevId[`${payload.devId}-${payload.bindingId}`] ||
          `${payload.devId}-${payload.bindingId}`;
        payload.loading = false;
        this.devs.push(payload);
      }
    });
    // So as to reconnect when logged out
    this.socket.connect();
  }

  toggleSwitch(pl: IRsPayload) {
    pl.loading = true;
    const toSend = Object.assign({}, pl);
    toSend.action = 3;
    console.log('pl', pl);
    if (pl.action === 4) {
      // digital switch
      if (toSend.boundState === 0) {
        // toSend.boundState = 1;
        toSend.manual = 1;
        // pl.boundState = 0;
      } else {
        // toSend.boundState = 0;
        toSend.manual = 2;
        // pl.boundState = 0;
      }
    } else if (pl.action === 16) {
      if (toSend.manual === 0) {
        // toSend.boundState = 1;
        toSend.manual = 2; // force off
        // pl.boundState = 0;
      } else if (toSend.manual === 2 || toSend.manual === 5) {
        // toSend.boundState = 0;
        toSend.manual = 3; // force to target then back to normal
        // pl.boundState = 0;
      } else if (toSend.manual === 3) {
        toSend.manual = 0;
      }
    } else if (pl.action === 19) {
      if (toSend.manual === 2) {
        toSend.target = 365; // 365: 100mL little meter
        toSend.manual = 4;
        toSend.valueB = 0;
      } else {
        toSend.manual = 2;
      }
    }
    console.log('sending', toSend);
    this.socket.emit('payload', toSend);
  }

  setManual(pl: IRsPayload, manual: number) {
    pl.loading = true;
    const toSend = Object.assign({}, pl);
    toSend.action = 3;
    toSend.manual = manual;
    this.socket.emit('payload', toSend);
  }

  saveChanges(pl: IRsPayload) {
    pl.loading = true;
    const toSend = Object.assign({}, pl);
    toSend.action = 3;
    this.socket.emit('payload', toSend);
  }

  sendMessage(msg: string) {
    // this.socket.emit('message', msg);
  }
  getMessage() {
    // return this.socket
    //   .fromEvent('message')
    //   .map(data => data.msg);
  }
}
