import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { MomentModule } from 'ngx-moment';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RsPayloadService } from './services/rsPayload.service';
import { FormsModule } from '@angular/forms';

// const config: SocketIoConfig = { url: 'http://192.168.1.18:8000/socket.io', options: { tranpost: 'websocket' } };
const config: SocketIoConfig = {
  url: 'http://kyngster.com:8000/socket.io',
  options: { tranpost: 'websocket' }
};

@NgModule({
  declarations: [AppComponent, DashboardComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxChartsModule,
    SocketIoModule.forRoot(config),
    MomentModule,
    ButtonsModule.forRoot(),
    FormsModule
  ],
  providers: [RsPayloadService],
  bootstrap: [AppComponent]
})
export class AppModule {}
